.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hidden {
  display: none;
}

.fr-login-widget .primary {
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  background-color: white;
  color: black;
  border-style: none;
  font-weight: bold;
  margin-bottom: 10px;
  margin-right: 5px;
}

.fr-login-widget {
  font-family: 'Open Sans';
  background-color: white;
  padding: 0px;
  border-radius: 10px;
}

.fr-login-widget .pre {
  padding: 15px;
  font-size: 15px;
  background-color: white;
  color: black;
  border-radius: 2px;
}

.fr-login-widget .text {
  font-weight: bold;
  background-color: white;
  color: black;
  border-radius: 3px;
  padding: 5px;
}

.fr-login-widget .actions {
  margin: 15px;
}

/* forgerock overrides */

.tw_button-primary {
  background-color: black;
  color: white;
}

.tw_input-base {
  background-color: white;
}

/* .tw_primary-header {
  text-align: left;
} */

.tw_input-stacked-label {
  font-weight: bold;
}

.tw_password-button {
  padding: 0px;
  border-width: 1px;
  border-color: #9ca3af;
}

.tw_input-stacked-label {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
}

.tw_input-spacing {
  margin-bottom: 30px;
}

#genericStepHeader {
  display: none;
}

.agree-message {
  margin-top: 20px !important;
}

.forgotten-password-container {
  margin-bottom: 20px;
}

.forgotten-password-container .row {
  display: flex;
  justify-content: end;
  margin: 0;
}

#IDToken1-message {
  font-size: 1.3rem;
  margin-top: 5px;
}

.tw_input-base {
  height: 48px;
}

.tw_password-button {
  padding: 10px;
}
